import {useState} from 'react';
import axios from 'axios';
import CGLogo from './chatGPT.png';
import AppLogo from './RMR_Logo.png';
import './App.css';

function App() {
  const [sireName, setSireName] = useState('');
  const [damName, setDamName] = useState('');
 // const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputBoxes = document.querySelectorAll('input[type="text"]');

  // Input form validation 
  inputBoxes.forEach(inputBox => {
    inputBox.addEventListener('input', event => {
      const inputValue = event.target.value;
      const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
  
      if (!alphanumericRegex.test(inputValue)) {
        event.target.value = inputValue.slice(0, -1);
      } else if (inputValue.length > 20) {
        event.target.value = inputValue.substring(0, 20);
      }
    });
  
    inputBox.addEventListener('paste', event => {
      event.preventDefault();
      const pastedText = event.clipboardData.getData('text/plain');
      const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
  
      if (alphanumericRegex.test(pastedText)) {
        document.execCommand('insertText', false, pastedText);
      }
    });
  });
  

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
  
  //GPT-3.5 Setting up the Message
  const prompt = [
    { role: "system", content: 'You are an expert regarding all things related to the breeding of the American Quarter Horse and the American Quarter Horse Association (AQHA).' },
    { role: "system", content: 'You specialize in producing catchy names based on the name of a Sire and Dam.' },
    { role: "system", content: 'When it comes to naming a new foal you understand that the name cannot be longer than 20 characters (this includes blank spaces and numbers).' },
    { role: "system", content: 'When it comes to naming a new foal you understand that Arabic numerals (1234 … and so on) are permitted at the end of a name, so long as there is a space between the name and number placed on the end.' },
    { role: "system", content: 'When it comes to naming a new foal you understand that punctuation marks are Not permitted.'},
    { role: "system", content: 'Remove apostrophe marks from your responses.' },
    { role: "system", content: 'Your responses should be numbered 1, 2, 3.' },
    { role: "user", content: `Give three name suggestions for a newborn foal sired by ${sireName} and out of ${damName}.` },
  ]

  // post input value 'prompt' to API end point 
    axios
      //.post(process.env.REACT_APP_API_ADDRESS + "/chat", { prompt })
      .post("/chat", { prompt })
      .then((res) => {
        setResponse(res.data);
        setLoading(false);
        // console.log(res.data)
      })
      .catch((err) => {
        console.error(err);
      });
    
  };

  return (
    <div className="wrapper">
      <a href="https://www.reinmakerranch.farm/"><img src={AppLogo} alt="" className="app-logo" /></a>
      <h1>Find the perfect name for your foal with AI suggestions</h1>
      <div className="body">
      <p>Welcome to our innovative web application designed to help you find the ideal name for your new foal. Using cutting-edge artificial intelligence technology from OpenAI, our application generates three unique name suggestions, each limited to twenty characters, based on the names of the foal's parents. Simply enter the sire's and dam's names into the input fields, and our system will quickly generate three possible names for your consideration. Whether you're a horse breeder or simply looking for the perfect name for your new equine companion, our web application makes the process quick, easy, and fun. Try it out today and discover the perfect name for your new foal!</p>
      </div>
      <form onSubmit={handleSubmit}>
      <img src={CGLogo} alt="" className={loading ? 'cg-logo loading' : 'cg-logo'} />
      
      <div>
        <label htmlFor="sireName">Sire Name:</label>
        <input
          id="sireName"
          type="text"
          value={sireName}
          onChange={(e) => setSireName(e.target.value)}
        />
      </div>

      <div>
        <label htmlFor="damName">Dam Name:</label>
        <input
          id="damName"
          type="text"
          value={damName}
          onChange={(e) => setDamName(e.target.value)}
        />
      </div>        

      <button type="submit">Ask</button>
      </form>
      <p className="response-area">
        {loading ? 'loading...' : response }
      </p>
      <p className="response-footer">
        {response ? 'If you would like three more suggestions then just hit the Ask button again.' : ''}
      </p>
      <div className="footer">~ Rein Maker Ranch ~</div>
</div>
  );
}

export default App;